[class*="MuiExpansionPanel-expanded"], [class*="MuiExpansionPanelSummary-expanded"]{
    min-height: 0 !important;
    border-bottom: 1px solid #bebebe;
}

[class*="MuiExpansionPanelSummary-root"] {
    padding: 0 !important;
}

[class*="MuiExpansionPanel-expanded"]:last-child {
    border-bottom: 0;
}

[class*="MuiExpansionPanel-root"]:last-child {
    border-bottom: 0;
}

td > [class*="MuiGrid-container"] > div,
[class*="MuiExpansionPanelDetails-root"] > tr {
    display: flex;
}

td > [class*="MuiGrid-container"] > div:not(:first-child) > div {
    display: block;
    width: 100%;
    text-align: center;
}

[class*="MuiExpansionPanelSummary-content"] > td:last-child {
    border-right: 0;
}

[class*="MuiExpansionPanelDetails-root"] > tr:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid #bebebe;
}

[class*="MuiExpansionPanelDetails-root"] > tr > td:last-child {
    border-right: 0;
    border-bottom: 0;
}

[class*="MuiExpansionPanelDetails-root"],
[class*="MuiExpansionPanelDetails-root"] > tr,
[class*="MuiExpansionPanelDetails-root"] > tr > td,
[class*="MuiExpansionPanelDetails-root"] > tr > td > div {
    width: 100%;
}

[class*="MuiExpansionPanelDetails-root"] > tr {
    height: 100%;
}

[class*="MuiExpansionPanelDetails-root"] > tr > td > div > div > [class*="MuiExpansionPanelSummary-content"] > td {
    border-bottom: 0;
}

[class*="MuiExpansionPanelDetails-root"] {
    padding: 0 !important;
    display: block !important;
}

[class*="MuiExpansionPanelSummary-content"] {
    background-color: #ededed;
    margin: 0 !important;
}

.border-right {
    border-right: 1px solid #cecece;
}

.v-center {
    margin-top: auto;
    margin-bottom: auto;
}

#custom-thead > th:first-child {
    width: 49px;
}

#custom-thead > th {
    text-align: left;
}

#custom-thead > th:nth-child(2) > div > div:not(:first-child) {
    text-align: center;
}

#custom-thead > th:nth-child(2) {
    padding: 0 12px;
}


