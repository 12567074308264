body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif;
  height: 100vh;
  background: #303030;
}

/* TODO FIXME : This is a hack to hide BuilkActionsToolbar */
.MuiToolbar-root[data-test='bulk-actions-toolbar'] {
  display: none !important;
}

.MuiTablePagination-root .MuiIconButton-root {
  color: black;
}

.MuiTablePagination-root .MuiIconButton-disabled {
  color: grey !important;
}

.blink {
  animation: blinker 1.8s ease-out infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}