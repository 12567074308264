.graph-head {
  background: url('graph_head.png') no-repeat;
  background-size: contain;
  height: 40px;
}

.graph-head-timer {
  position: absolute;
  font-size: 1.5em;
  font-family: arial, sans-serif;
  display: flex;
  transform: translateY(20%);
  padding-left: 1em;
  align-items: center;
}

.graph-poi {
  display: flex;
  padding: 1em 1em 0 1em;
  background-color: #ececec;
  border-top-right-radius: 6px;
  font-family: arial, sans-serif;
}

.graph-poi > div > span {
  display: inline-block;
  transform: translateX(-50%);
}

.graph-container {
  background-color: #ececec;
  padding: 0 1em 1em 1em;
  border-radius: 0 0 6px 6px;
  font-family: arial, sans-serif;
}

.graph-parameter-container > div:first-child {
  border-radius: 4px 0 0 4px;
}

.graph-parameter-container > div:last-child {
  border-radius: 0 4px 4px 0;
}

.graph-counter > div {
  height: 10px;
  border-right: 2px dotted #909090;
  display: inline-block;
}
