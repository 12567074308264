.not-resolved {
    color: #F7B235;
    display: flex;
}

.errors {
    display: flex;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.date {
    flex: 1;
    justify-content: space-evenly;
    display: flex;
}
