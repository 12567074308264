.save-toolbar {
  text-align: center;
  background: #fff;
}

.save-toolbar button {
  margin: 0 0 10px 0;
  border: 1px solid #f14343;
  border-radius: 3px;
}

.save-toolbar svg {
  display: none;
}

.tabbed-form [class*='MuiTabs-indicator-'] {
  display: none;
}

.tabbed-form .form-tab:first-child {
  background: url('icon_debut.svg') center no-repeat;
}

.tabbed-form .form-tab {
  background: url('icon_etape.svg') center no-repeat;
  color: white;
  pointer-events: none;
  width: 12.5em;
  font-weight: 400;
}

.tabbed-form .form-tab:last-child {
  background: url('icon_fin.svg') center no-repeat;
}
