.sort-number {
    font-size: 1.5em;
    text-align: right;
    width: 100%;
}

.name-program {
    width: 100%;
}

input::-webkit-inner-spin-button {
    margin-left: 10px;
}

.informations-left {
    text-align: right;
    opacity: 0.6;
}

.informations {
    padding: 1em 0.5em;
    font-style: italic;
}

.program-separator {
    border-bottom: 2px solid black;
}

.img-fluid {
    width: 100%;
}

.align-right {
    text-align: right;
}
