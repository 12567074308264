#navbar-menu > div[role='document'] {
  background-color: #363636;
  margin-top: 3em;
  padding: 0.4em;
  transform: translateX(-1em);
}

#navbar-menu > div[role='document'] > ul > a > li[role='menuitem'] {
  color: white;
  font-weight: 300;
}
