a > span {
    text-transform: none;
}

.bg-tag {
    background: url('icon_etiquette_on.svg') center no-repeat;
    background-size: 75% 75%;
}

.bg-mall {
    background: url('storemall.svg') center no-repeat;
    background-size: 75% 75%;
}

.show-info {
    background: whitesmoke;
    border-radius: 2px;
}

.show-info span {
    font-size: 14px;
}

.show-info label {
    transform: translate(0, 1.5px);
    font-weight: 700;
}

.show-info-content {
    padding: 1em 0;
}

.show-info-content > div {
    padding-top: 0;
    padding-bottom: 0;
}

.show-info-content > div:nth-child(2) {
    padding-left: 1em;
    border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.edit-info {
    margin-right: 1em;
    text-align: right;
}

.edit-avatar {
    text-align: center;
}

.tag-content {
    margin-top: 2em;
    padding-left: 1em;
}

.tag-content > div {
    margin-bottom: 1em;
}

.tag-content > div:not(:first-child) {
    padding-top: 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.program-tag-field {
    padding-left: 1em;
    height: 50px;
    background: url('etiquette_off.svg') center no-repeat;
    background-size: cover;
}

.font-bold p {
    font-weight: 600;
}
