#locale-menu > div[role="document"] {
    background-color: #363636;
    margin-top: 3em;
    padding: 1em;
    transform: translateX(-1em);
}

#locale-menu > div[role="document"] > ul > li[role="menuitem"] {
    color: white;
}
