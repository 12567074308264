.Loading-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
}

.Loading-progress {
  position: relative;
  top: 40%;
  display: block !important;
  margin: auto;
}
