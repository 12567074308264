.resume:before {
    content: '';
    display: inline-block;
    height: 2em;
    width: 2em;
    background-image: url('resume.svg');
    background-repeat: no-repeat;
}

.resume > span {
    vertical-align: 17px;
    padding-left: 15px;
    font-weight: 400;
}

.notes:before {
    content: '';
    display: inline-block;
    height: 2em;
    width: 2em;
    background-image: url('note.svg');
    background-repeat: no-repeat;
}

.notes > span {
    vertical-align: 28px;
    padding-left: 15px;
    font-weight: 400;
}

.basics:before {
    content: '';
    display: inline-block;
    height: 2em;
    width: 2em;
    background-image: url('basiques.svg');
    background-repeat: no-repeat;
}

.basics > span {
    vertical-align: 22px;
    padding-left: 15px;
    font-weight: 400;
}

.programs:before {
    content: '';
    position: absolute;
    height: 2em;
    width: 1em;
    margin-left: -35px;
    background-image: url('programmes.svg');
    background-repeat: no-repeat;
}

.machines:before {
    content: '';
    position: absolute;
    height: 1.3em;
    width: 1.3em;
    margin-left: -30px;
    background-image: url('machines.svg');
    background-repeat: no-repeat;
}

.users:before {
    content: '';
    position: absolute;
    height: 1.3em;
    width: 1.6em;
    margin-left: -40px;
    background-image: url('utilisateurs.svg');
    background-repeat: no-repeat;
}

.tags:before {
    content: '';
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    margin-left: -35px;
    background-image: url('etiquettes.svg');
    background-repeat: no-repeat;
}

.structures:before {
    content: '';
    position: absolute;
    height: 1.1em;
    width: 2em;
    margin-left: -40px;
    background-image: url('structures.svg');
    background-repeat: no-repeat;
}
