

.Loading-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
}

.Loading-progress {
  position: relative;
  top: 40%;
  display: block !important;
  margin: auto;
}

a > span {
    text-transform: none;
}

.bg-tag {
    background: url(/static/media/icon_etiquette_on.2c5331e7.svg) center no-repeat;
    background-size: 75% 75%;
}

.bg-mall {
    background: url(/static/media/storemall.5854d14f.svg) center no-repeat;
    background-size: 75% 75%;
}

.show-info {
    background: whitesmoke;
    border-radius: 2px;
}

.show-info span {
    font-size: 14px;
}

.show-info label {
    -webkit-transform: translate(0, 1.5px);
            transform: translate(0, 1.5px);
    font-weight: 700;
}

.show-info-content {
    padding: 1em 0;
}

.show-info-content > div {
    padding-top: 0;
    padding-bottom: 0;
}

.show-info-content > div:nth-child(2) {
    padding-left: 1em;
    border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.edit-info {
    margin-right: 1em;
    text-align: right;
}

.edit-avatar {
    text-align: center;
}

.tag-content {
    margin-top: 2em;
    padding-left: 1em;
}

.tag-content > div {
    margin-bottom: 1em;
}

.tag-content > div:not(:first-child) {
    padding-top: 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.program-tag-field {
    padding-left: 1em;
    height: 50px;
    background: url(/static/media/etiquette_off.570da906.svg) center no-repeat;
    background-size: cover;
}

.font-bold p {
    font-weight: 600;
}

[class*="MuiExpansionPanel-expanded"], [class*="MuiExpansionPanelSummary-expanded"]{
    min-height: 0 !important;
    border-bottom: 1px solid #bebebe;
}

[class*="MuiExpansionPanelSummary-root"] {
    padding: 0 !important;
}

[class*="MuiExpansionPanel-expanded"]:last-child {
    border-bottom: 0;
}

[class*="MuiExpansionPanel-root"]:last-child {
    border-bottom: 0;
}

td > [class*="MuiGrid-container"] > div,
[class*="MuiExpansionPanelDetails-root"] > tr {
    display: flex;
}

td > [class*="MuiGrid-container"] > div:not(:first-child) > div {
    display: block;
    width: 100%;
    text-align: center;
}

[class*="MuiExpansionPanelSummary-content"] > td:last-child {
    border-right: 0;
}

[class*="MuiExpansionPanelDetails-root"] > tr:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid #bebebe;
}

[class*="MuiExpansionPanelDetails-root"] > tr > td:last-child {
    border-right: 0;
    border-bottom: 0;
}

[class*="MuiExpansionPanelDetails-root"],
[class*="MuiExpansionPanelDetails-root"] > tr,
[class*="MuiExpansionPanelDetails-root"] > tr > td,
[class*="MuiExpansionPanelDetails-root"] > tr > td > div {
    width: 100%;
}

[class*="MuiExpansionPanelDetails-root"] > tr {
    height: 100%;
}

[class*="MuiExpansionPanelDetails-root"] > tr > td > div > div > [class*="MuiExpansionPanelSummary-content"] > td {
    border-bottom: 0;
}

[class*="MuiExpansionPanelDetails-root"] {
    padding: 0 !important;
    display: block !important;
}

[class*="MuiExpansionPanelSummary-content"] {
    background-color: #ededed;
    margin: 0 !important;
}

.border-right {
    border-right: 1px solid #cecece;
}

.v-center {
    margin-top: auto;
    margin-bottom: auto;
}

#custom-thead > th:first-child {
    width: 49px;
}

#custom-thead > th {
    text-align: left;
}

#custom-thead > th:nth-child(2) > div > div:not(:first-child) {
    text-align: center;
}

#custom-thead > th:nth-child(2) {
    padding: 0 12px;
}



.graph-head {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAW8AAAA3CAYAAADDlss6AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH4goKCRgIMUTDCAAAAzNJREFUeNrt3V1ymzAUhuEPAcZxvBv3LvtJ15SuqV1MEoSFLDi9MZm200yT5scI3ufG90rmHc1BoMLMJEnjOOp4PFrf9wIwb0VRPPvrnHv6dc798N5/YcUW+D9gZhqGQY+PjzaOIysCLNwU97IsVZYlgc853g8PD5ZSYjUAwv4U9aqqvrZt+41VmWm8U0q6v783lgLAc0GvqkpVVanruoIVmcnfJaX0nWUA8JxxHBVjVNd1kmRFUdhms7Hr62vacUEVSwDgNcxMMUbFGA+SrCxL1XWtEAK78s/cebMEAN5iGAaFECTJnHN2dXXFGJZ4A8jJ+cixJFlVVYxWiDeA3KSU5L0/SLKmadiNE28AuTm//GebzYaIE28AuYkxSpLVdU3EiTeA3JxOJyJOvAHkHvHtdkvEiTeA3IQQVBQFp1OIN4DcmJm894eyLNmFE28AuRmGQWIeTrwB5GmahzNKId4AMuS9P3A+nHgDyFCMUc45Ak68AeTmfNMXxwqJN4AchRBUVdXqA068Z2y6HBrA787XNq76YSbxnnG4pxvBAfzdmh9mEm/CDWRtrQ8zifcMEW7gdaaHmWu6xYd4A1iM4/G4moeZxBvAoqSUVvGRK+INYHGmj1wt+WEm8QawWDFGLfUrhcQbwKJNXylc2hiFeANYBe/9YUm32BNvAKvR9/1ixijEG8CqTGOU/X5/S7wBIDNt297lPEYh3gBWK+cxCvEGsGrDMKgoiuzGKMQbwOqZmdq2vcvpogfiDQBnIYRsxijEGwB+kcsYhXgDwB+mMcqcv41CvAHgGTHG2e7CiTcAvGAXXtf1rHbhxBsAXuB0Os3qO+HEGwBesQv33h/msAsn3gDwH7twSXbJc+HEGwD+UwjhYqMU4g0AbzCNUj775R7iDQDvYPrU7GfdXk+8AeAdpZQkycqy/NDz4cQbAD5oJ9627Z1zzna73bvvxok3AHygcRzVdZ0k2Xu+bk+8AeCTxBglyZxzbz5mSLwB4AK78RDCU8ibpnn1fJx4A8CFQ973vdq2vdP5Qed2u/3n2XHivVI3NzcsAjBDwzAohCDv/UGSTbvzuq6taRrb7Xa23+9vfwK6ToI0u6tLZQAAAABJRU5ErkJggg==) no-repeat;
  background-size: contain;
  height: 40px;
}

.graph-head-timer {
  position: absolute;
  font-size: 1.5em;
  font-family: arial, sans-serif;
  display: flex;
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  padding-left: 1em;
  align-items: center;
}

.graph-poi {
  display: flex;
  padding: 1em 1em 0 1em;
  background-color: #ececec;
  border-top-right-radius: 6px;
  font-family: arial, sans-serif;
}

.graph-poi > div > span {
  display: inline-block;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.graph-container {
  background-color: #ececec;
  padding: 0 1em 1em 1em;
  border-radius: 0 0 6px 6px;
  font-family: arial, sans-serif;
}

.graph-parameter-container > div:first-child {
  border-radius: 4px 0 0 4px;
}

.graph-parameter-container > div:last-child {
  border-radius: 0 4px 4px 0;
}

.graph-counter > div {
  height: 10px;
  border-right: 2px dotted #909090;
  display: inline-block;
}

.save-toolbar {
  text-align: center;
  background: #fff;
}

.save-toolbar button {
  margin: 0 0 10px 0;
  border: 1px solid #f14343;
  border-radius: 3px;
}

.save-toolbar svg {
  display: none;
}

.tabbed-form [class*='MuiTabs-indicator-'] {
  display: none;
}

.tabbed-form .form-tab:first-child {
  background: url(/static/media/icon_debut.10e40717.svg) center no-repeat;
}

.tabbed-form .form-tab {
  background: url(/static/media/icon_etape.f9abf60a.svg) center no-repeat;
  color: white;
  pointer-events: none;
  width: 12.5em;
  font-weight: 400;
}

.tabbed-form .form-tab:last-child {
  background: url(/static/media/icon_fin.2303fda3.svg) center no-repeat;
}

.not-resolved {
    color: #F7B235;
    display: flex;
}

.errors {
    display: flex;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.date {
    flex: 1 1;
    justify-content: space-evenly;
    display: flex;
}

.sort-number {
    font-size: 1.5em;
    text-align: right;
    width: 100%;
}

.name-program {
    width: 100%;
}

input::-webkit-inner-spin-button {
    margin-left: 10px;
}

.informations-left {
    text-align: right;
    opacity: 0.6;
}

.informations {
    padding: 1em 0.5em;
    font-style: italic;
}

.program-separator {
    border-bottom: 2px solid black;
}

.img-fluid {
    width: 100%;
}

.align-right {
    text-align: right;
}

#navbar-menu > div[role='document'] {
  background-color: #363636;
  margin-top: 3em;
  padding: 0.4em;
  -webkit-transform: translateX(-1em);
          transform: translateX(-1em);
}

#navbar-menu > div[role='document'] > ul > a > li[role='menuitem'] {
  color: white;
  font-weight: 300;
}

#locale-menu > div[role="document"] {
    background-color: #363636;
    margin-top: 3em;
    padding: 1em;
    -webkit-transform: translateX(-1em);
            transform: translateX(-1em);
}

#locale-menu > div[role="document"] > ul > li[role="menuitem"] {
    color: white;
}

.resume:before {
    content: '';
    display: inline-block;
    height: 2em;
    width: 2em;
    background-image: url(/static/media/resume.efbc8e41.svg);
    background-repeat: no-repeat;
}

.resume > span {
    vertical-align: 17px;
    padding-left: 15px;
    font-weight: 400;
}

.notes:before {
    content: '';
    display: inline-block;
    height: 2em;
    width: 2em;
    background-image: url(/static/media/note.9c96b2c2.svg);
    background-repeat: no-repeat;
}

.notes > span {
    vertical-align: 28px;
    padding-left: 15px;
    font-weight: 400;
}

.basics:before {
    content: '';
    display: inline-block;
    height: 2em;
    width: 2em;
    background-image: url(/static/media/basiques.966f1634.svg);
    background-repeat: no-repeat;
}

.basics > span {
    vertical-align: 22px;
    padding-left: 15px;
    font-weight: 400;
}

.programs:before {
    content: '';
    position: absolute;
    height: 2em;
    width: 1em;
    margin-left: -35px;
    background-image: url(/static/media/programmes.be44bace.svg);
    background-repeat: no-repeat;
}

.machines:before {
    content: '';
    position: absolute;
    height: 1.3em;
    width: 1.3em;
    margin-left: -30px;
    background-image: url(/static/media/machines.5df87c38.svg);
    background-repeat: no-repeat;
}

.users:before {
    content: '';
    position: absolute;
    height: 1.3em;
    width: 1.6em;
    margin-left: -40px;
    background-image: url(/static/media/utilisateurs.8efc6e11.svg);
    background-repeat: no-repeat;
}

.tags:before {
    content: '';
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    margin-left: -35px;
    background-image: url(/static/media/etiquettes.1b986002.svg);
    background-repeat: no-repeat;
}

.structures:before {
    content: '';
    position: absolute;
    height: 1.1em;
    width: 2em;
    margin-left: -40px;
    background-image: url(/static/media/structures.52bceb70.svg);
    background-repeat: no-repeat;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif;
  height: 100vh;
  background: #303030;
}

/* TODO FIXME : This is a hack to hide BuilkActionsToolbar */
.MuiToolbar-root[data-test='bulk-actions-toolbar'] {
  display: none !important;
}

.MuiTablePagination-root .MuiIconButton-root {
  color: black;
}

.MuiTablePagination-root .MuiIconButton-disabled {
  color: grey !important;
}

.blink {
  -webkit-animation: blinker 1.8s ease-out infinite;
          animation: blinker 1.8s ease-out infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
